import styled from 'styled-components';
import { Divider as UnstyledDivider } from 'antd';

import UnstyledContactInfo from './ContactInfo';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(200px, 1200px) 1fr;
  grid-template-rows: 70px 70px auto;

  @media screen and (max-width: 770px) {
    grid-template-rows: auto auto auto;
  }
`;

export const BackgroundContainer = styled.div`
  grid-column: 1 / 4;
  grid-row: 2 / 4;

  background-color: #0d2352;
`;

export const Container = styled.div`
  grid-column: 2;
  grid-row: 1 / 3;

  display: grid;
  grid-template-rows: repeat(3, 1fr);
`;

export const ContactInfoContainer = styled.div`
  padding: 0 16px;
  grid-column: 1 / 4;
  grid-row: 1 / 3;

  display: grid;
  /* grid-template-columns: repeat(auto-fit, 1fr); */
  grid-template-columns: 1fr 10px 1fr 10px 1fr;
  background-color: #183369;

  @media screen and (max-width: 770px) {
    grid-template-columns: 1fr;
  }
`;

export const Background = styled.div`
  grid-column: span 3;
  grid-row: 1 / 3;

  background-color: darkred;
`;

export const ContactInfo = styled(UnstyledContactInfo)`
  @media screen and (max-width: 770px) {
    justify-self: center;
    margin: 16px 0;
  }
`;

export const Copyright = styled.div`
  grid-column: span 3;
  grid-row: 3;
  align-self: center;

  color: #d2d4e0;
  margin-left: 16px;
`;

export const Divider = styled(UnstyledDivider)`
  @media screen and (max-width: 770px) {
    display: none;
  }
`;
