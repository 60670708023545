import styled from 'styled-components';

export const Container = styled.div``;

export const NumericCharacter = styled.p`
  font-size: 2rem;
  color: #eb3f57;
`;

export const Title = styled.h5`
  margin-top: 20px;
  text-transform: uppercase;
  color: #201f1f;
  font-weight: normal;
  font-size: 1.1rem;
`;

export const Description = styled.p`
  margin-top: 20px;
  color: #9e9e9e;
  font-weight: 300;
  font-size: 1.2rem;
`;
