import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px 0;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 50px auto 50px;
  grid-column-gap: 32px;

  background-color: #183369;
`;

// export const ContainerBackground = styled.div`
//   grid-column: 1 / 5;
//   grid-row: 1 / 3;
//   max-height: 100%;
//   background-color: #183369;
// `;

export const Title = styled.h5`
  grid-column: 1 / 4;
  grid-row: 1;
  align-self: center;

  margin-left: 16px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #eeeeee;
`;

export const OurAppsList = styled.div`
  grid-column: 1 / 5;
  grid-row: 2 / 4;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-row-gap: 32px;
  grid-column-gap: 16px;

  padding: 0 16px;
  margin-top: 16px;
`;

export const AppLogos = styled.div`
  grid-column: 1 / 5;
  grid-row: 5;
  margin-top: 48px;

  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 8px;
  justify-content: center;
`;
