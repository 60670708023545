import styled from 'styled-components';

import UnstyledHeaderNav from './components/HeaderNav';
import UnstyledAdvantagesSection from './components/AdvantagesSection';
import UnstyledOurAppsSection from './components/OurAppsSection';
import UnstyledOurServicesSection from './components/OurServicesSection';
import UnstyledFooterSection from './components/FooterSection';

import backgroundImage from '../../assets/images/inova-header-background.png';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(200px, 1200px) 1fr;
  grid-template-rows: 100vh auto minmax(350px, auto) auto auto auto;
`;

export const HeaderBackgroundContainer = styled.div`
  grid-row: 1;
  grid-column: 1 / 4;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* opacity: 0.5; */
`;

export const HeaderContent = styled.div`
  grid-row: 1;
  grid-column: 1 / 4;

  display: grid;
  grid-template-columns: 1fr minmax(200px, 1200px) 1fr;
  grid-template-rows: 80px 1fr;
`;

export const HeaderNavBackground = styled.div`
  grid-row: 1;
  grid-column: 1 / 4;

  max-height: 62px;

  border-bottom: 4px solid rgba(255, 255, 255, 0.1);
`;

export const HeaderNav = styled(UnstyledHeaderNav)`
  grid-row: 1;
  grid-column: 2;
  align-self: center;
  color: white;
  padding: 0 16px;
`;

export const HeaderText = styled.p`
  grid-row: 2 / 3;
  grid-column: 2;
  align-self: center;
  font-size: 2rem;
  font-weight: 200;
  color: white;
  padding: 0 16px;

  p {
    &:nth-child(1) {
      font-size: 1.8rem;
    }
    &:nth-child(2) {
      font-size: 1.4rem;
    }
    &:nth-child(3) {
      font-size: 1.8rem;
    }
  }

  @media screen and (min-width: 770px) {
    p {
      &:nth-child(1) {
        font-size: 3rem;
      }
      &:nth-child(2) {
        font-size: 1.8rem;
      }
      &:nth-child(3) {
        font-size: 3rem;
      }
    }
  }
`;

export const AdvantagesSection = styled(UnstyledAdvantagesSection)`
  grid-row: 2;
  grid-column: 2;
`;

export const OurAppsSectionBackground = styled.div`
  /* margin-top: 16px; */
  max-height: 350px;

  grid-column: 1 / 4;
  grid-row: 3;
  background-color: #183369;
`;

export const OurAppsSection = styled(UnstyledOurAppsSection)`
  grid-row: 3;
  grid-column: 2;
`;

export const OurServicesSection = styled(UnstyledOurServicesSection)`
  margin: 32px 0;
  grid-row: 4;
  grid-column: 2;
`;

export const FooterSection = styled(UnstyledFooterSection)`
  margin-top: 32px;
  grid-column: span 3;
`;

export const AppLogos = styled.div`
  grid-row: 2 / 3;
  grid-column: 2;
  margin-top: 48px;

  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 8px;
  justify-content: center;
`;
