import React from 'react';

import {
  Container,
  HeaderBackgroundContainer,
  HeaderContent,
  HeaderText,
  HeaderNav,
  OurAppsSectionBackground,
  OurAppsSection,
  OurServicesSection,
  AdvantagesSection,
  FooterSection,
  HeaderNavBackground
} from './styles';

const Home = () => (
  <Container>
    <HeaderBackgroundContainer />
    <HeaderContent>
      <HeaderNavBackground />
      <HeaderNav />
      <HeaderText>
        <p style={{ marginBottom: '1.5rem' }}>
          A evolução do{' '}
          <span style={{ fontWeight: 700 }}>Estacionamento</span>.
        </p>
        <p style={{ marginBottom: '1.5rem' }}>
          Maior comodidade na hora de estacionar o seu carro. <br/>Facilidade para reservar vagas e contratar serviços automotivos.
        </p>
        <p>
          Fácil. Digital. <span style={{ fontWeight: 700 }}>Inova</span>dor.
        </p>
      </HeaderText>
    </HeaderContent>
    <AdvantagesSection />
    <OurAppsSectionBackground />
    <OurAppsSection />
    <OurServicesSection />
    <FooterSection />
  </Container>
);

export default Home;
