import React from 'react';

import { Container, Title, OurAppsList } from './styles';
import fazDigitalBhAppIcon from '../../../../assets/images/faz-digital-bh-app-icon.png';
import fazDigitalCuritibaAppIcon from '../../../../assets/images/faz-digital-curitiba-app-icon.png';
import fazDigitalFortalezaAppIcon from '../../../../assets/images/faz-digital-fortaleza-app-icon.png';
import fazDigitalSalvadorAppIcon from '../../../../assets/images/faz-digital-salvador-app-icon.png';

import AppItem from './AppItem';

const OurAppsSection = props => {
  const apps = [
    {
      id: 1,
      image: fazDigitalBhAppIcon,
      landingPageUrl: 'https://faixaazuldigitalbh.com/',
      appleStoreUrl:
        'https://itunes.apple.com/br/app/faz-rotativo-digital-bh/id1394646230',
      playStoreUrl:
        'https://play.google.com/store/apps/details?id=com.rotativo.digital.faixa.azul.bh.belo.horizonte.bhtrans.estacionamento'
    },
    {
      id: 2,
      image: fazDigitalCuritibaAppIcon,
      landingPageUrl: 'https://estarcuritiba.app/',
      appleStoreUrl:
        'https://apps.apple.com/br/app/estar-digital-curitiba-faz/id1497474349',
      playStoreUrl:
        'https://play.google.com/store/apps/details?id=com.estar.digital.curitiba.estacionamento.eletronico.rotativo.urbs.faz'
    },
    {
      id: 3,
      image: fazDigitalFortalezaAppIcon,
      landingPageUrl: 'https://fazdigitalfortaleza.com/',
      appleStoreUrl:
        'https://itunes.apple.com/br/app/faz-zona-azul-fortaleza/id1425293453',
      playStoreUrl:
        'https://play.google.com/store/apps/details?id=com.zona.azul.digital.cartao.azul.fortaleza.amc.estacionamento.rotativo'
    },
    {
      id: 4,
      image: fazDigitalSalvadorAppIcon,
      landingPageUrl: 'https://www.zonaazuldigitalsalvador.com/',
      appleStoreUrl:
        'https://itunes.apple.com/br/app/faz-zona-azul-digital-salvador/id1438274937',
      playStoreUrl:
        'https://play.google.com/store/apps/details?id=com.zona.azul.digital.salvador.estacionamento.rotativo.transalvador'
    }
    
  ];

  return (
    <Container {...props}>
      {/* <ContainerBackground /> */}
      <Title>NOSSOS APPS</Title>
      <OurAppsList>
        {apps.map(app => (
          <AppItem key={app.id} {...app} />
        ))}
      </OurAppsList>
      {/* <AppLogos>
        <img src={zonaAzulFacilLogo} width={100} />
        <img src={fazDigitalLogo} width={100} />
      </AppLogos> */}
    </Container>
  );
};

export default OurAppsSection;
