import React from 'react';

import { Container, Menu } from './styles';

const Links = props => {
  return (
    <Container {...props}>
      <Menu
        mode="horizontal"
        selectedKeys={[]}
        style={{
          backgroundColor: 'transparent',
          border: 'none'
        }}
      >
        <Menu.Item key="beloHorizonte" className="ant-menu-item-styled">
          <a
            href="https://faixaazuldigitalbh.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Belo Horizonte
          </a>
        </Menu.Item>
        <Menu.Item key="curitiba" className="ant-menu-item-styled">
          <a
            href="https://estarcuritiba.app/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Curitiba
          </a>
        </Menu.Item>
        <Menu.Item key="fortaleza" className="ant-menu-item-styled">
          <a
            href="https://fazdigitalfortaleza.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Fortaleza
          </a>
        </Menu.Item>
        <Menu.Item key="salvador" className="ant-menu-item-styled">
          <a
            href="https://www.zonaazuldigitalsalvador.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Salvador
          </a>
        </Menu.Item>
      </Menu>
    </Container>
  );
};

export default Links;
