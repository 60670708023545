import styled from 'styled-components';
import { Menu as UnstyledMenu } from 'antd';

export const Container = styled.div`
  color: white;

  h1 {
    color: white;
    font-weight: 700;
  }
`;

export const Menu = styled(UnstyledMenu)`
  font-family: 'Circe Sans';

  .ant-menu-item {
    color: #bdbdbd;
    border-width: 4px;

    a {
      color: #bdbdbd;
    }
    &.ant-menu-item-selected {
      color: white;
      border-color: #eb3f57;
      border-width: 4px;
    }
  }

  .ant-menu-item:hover {
    color: white;
    border-color: #eb3f57;
    border-width: 4px;

    a {
      color: white;
    }
  }

  .ant-menu-item-selected {
    color: white;
    border: none;

    a {
      color: white;
    }
  }
`;
