import React from 'react';
import {
  faMapMarkerAlt,
  faEnvelope,
  faPhone
} from '@fortawesome/free-solid-svg-icons';

import {
  Wrapper,
  Container,
  BackgroundContainer,
  ContactInfoContainer,
  ContactInfo,
  Copyright,
  Divider
} from './styles';

const FooterSection = props => {
  return (
    <Wrapper {...props}>
      <BackgroundContainer />
      <Container>
        <ContactInfoContainer>
          {/* <ContactInfo
            title="Endereço"
            description="Alameda Santos, 2.326, São Paulo, SP"
            icon={faMapMarkerAlt}
          /> */}
          {/* <Divider
            type="vertical"
            style={{ alignSelf: 'center', justifySelf: 'center' }}
          /> */}
          <ContactInfo
            title="E-mail"
            description="contato@inova.app"
            icon={faEnvelope}
          />
          {/* <Divider
            type="vertical"
            style={{ alignSelf: 'center', justifySelf: 'center' }}
          /> */}
          {/* <ContactInfo
            title="Telefone"
            description="+55 (11) 3337-0377"
            icon={faPhone}
          /> */}
        </ContactInfoContainer>
        <Copyright>
          Copyright © Inova {new Date().getFullYear()}. Todos os direitos
          reservados.
        </Copyright>
      </Container>
    </Wrapper>
  );
};

export default FooterSection;
