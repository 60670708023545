import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr) 100px 100px repeat(2, 1fr);
  grid-template-rows: 200px 50px;
`;

export const ImageContainer = styled.div`
  grid-column: 3 / 5;
  width: 200px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img``;

export const ButtonsContainer = styled.div`
  grid-column: 3 / 5;
  /* background-color: #eb3f57; */

  display: grid;
  grid-template-columns: 1fr 10px 1fr;
`;

export const FirstButton = styled.a`
  color: rgba(255, 255, 255, 0.8);
  align-self: center;
  justify-self: center;

  &:hover {
    color: rgba(255, 255, 255, 1);
  }
`;

export const SecondButton = styled.a`
  color: rgba(255, 255, 255, 0.8);
  align-self: center;
  justify-self: center;

  &:hover {
    color: rgba(255, 255, 255, 1);
  }
`;
