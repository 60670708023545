import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Container = styled.div`
  align-self: center;
  justify-self: center;
  color: white;

  display: grid;
  grid-template-columns: 50px max-content;
  grid-template-rows: 50px max-content;
  grid-column-gap: 16px;

  @media screen and (max-width: 770px) {
    grid-template-columns: 50px 200px;
  }
`;

export const FAIcon = styled(FontAwesomeIcon)`
  justify-self: center;
  grid-column: 1;
  grid-row: 1 / 3;
`;

export const TextContainer = styled.div`
  grid-column: 2;
  grid-row: 1 / 3;
`;

export const Title = styled.p`
  margin: 0;
  font-weight: 200;
`;

export const Description = styled.p`
  margin: 0;
  font-weight: 800;
`;
