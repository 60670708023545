import React from 'react';

import { Container, FAIcon, TextContainer, Title, Description } from './styles';

const FooterSection = ({ title, description, icon, ...rest }) => {
  return (
    <Container {...rest}>
      <FAIcon icon={icon} size="3x" />
      <TextContainer>
        <Title>{title}</Title>
        {title === 'Localização' ? (
          <>
            <Description>
              <span>Alameda Santos, 2326, </span>
            </Description>
            <Description>
              <span>São Paulo, SP</span>
            </Description>
          </>
        ) : (
          <Description>{description}</Description>
        )}
      </TextContainer>
    </Container>
  );
};

export default FooterSection;
