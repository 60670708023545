import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;
`;

export const Title = styled.h3`
  font-size: 1.4rem;
  text-align: center;
  font-weight: bolder;
  color: #424242;

  @media screen and (min-width: 770px) {
    font-size: 1.5rem;
  }
`;
export const Description = styled.p`
  font-size: 3rem;
  font-weight: 200;
  color: #201f1f;
`;

export const ServicesList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-row-gap: 64px;
  grid-column-gap: 16px;

  margin-top: 5rem;
`;
