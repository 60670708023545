import React from 'react';

import { Container, Title, Description, AdvantagesList } from './styles';
import Advantage from './Advantage';

const AdvantagesSection = props => {
  const advantages = [
    {
      id: 1,
      title: 'Comodidade',
      description:
        'Dê adeus ao papel. Nossos apps permitem o pagamento e utilização de serviços exclusivamente pelo celular e pelo computador.'
    },
    {
      id: 2,
      title: 'Segurança',
      description:
        'Utilizamos um conjunto de tecnologias de segurança para proteger os dados dos nossos usuários.'
    },
    {
      id: 3,
      title: 'Transparência',
      description:
        'Oferecemos ferramentas para que nossos usuários acompanhem suas compras, ativações e demais processos.'
    },
    {
      id: 4,
      title: 'Preço',
      description:
        'O preço é justo. Sempre.'
    },
    {
      id: 5,
      title: 'Formas de pagamento',
      description:
        'Disponibilizamos diversas formas de pagamento, tais como: boleto, cartão de crédito, débito e transferência bancária.'
    },
    {
      id: 6,
      title: 'Qualidade',
      description:
        'Nossos apps são rápidos, estáveis, sem erros e muito bem avaliados nas lojas da Apple e do Google.'
    }
  ];

  return (
    <Container {...props}>
      <Title>Nossas vantagens</Title>
      <Description>
        Porque nossos apps
        <br /> possuem mais de 1 milhão de downloads
      </Description>
      <AdvantagesList>
        {advantages.map(advantage => (
          <Advantage
            key={advantage.id}
            number={advantage.id}
            title={advantage.title}
            description={advantage.description}
          />
        ))}
      </AdvantagesList>
    </Container>
  );
};

export default AdvantagesSection;
