import styled from 'styled-components';

export const Container = styled.div`
  color: white;

  display: flex;
  justify-content: space-between;

  h1 {
    color: white;
    font-weight: 700;
  }
`;
