import React from 'react';
import { Menu, Dropdown, Button } from 'antd';

import { Container } from './styles';

const MenuLinks = props => {
  const menu = (
    <Menu>
      <Menu.Item key="1">
        <a
          href="https://faixaazuldigitalbh.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Belo Horizonte
        </a>
      </Menu.Item>
      <Menu.Item key="3">
        <a
          href="https://estarcuritiba.app/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Curitiba
        </a>
      </Menu.Item>
      <Menu.Item key="3">
        <a
          href="https://fazdigitalfortaleza.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Fortaleza
        </a>
      </Menu.Item>
      <Menu.Item key="2">
        <a
          href="https://www.zonaazuldigitalsalvador.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Salvador
        </a>
      </Menu.Item>
      {/* <Menu.Item key="0">
        <a
          href="https://zonaazulfacil.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          São Paulo
        </a>
      </Menu.Item> */}
      
    </Menu>
  );

  return (
    <Container {...props}>
      <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft">
        <Button
          type="primary"
          shape="circle"
          icon="align-right"
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            alignSelf: 'center'
          }}
        />
      </Dropdown>
    </Container>
  );
};

export default MenuLinks;
