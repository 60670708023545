import React from 'react';

import { Container, Title } from './styles';

const OurServicesSection = props => {
  return (
    <Container {...props}>
      <Title>
        A Inova trabalha continuamente para <br />
        oferecer aos seus usuários a melhor experiência <br />
        em estacionamento e em serviços para o veículo. <br /> <br />
        Conte conosco ;)
      </Title>
    </Container>
  );
};

export default OurServicesSection;
