import React from 'react';

import { Container } from './styles';
import Links from './Links';
import MenuLinks from './MenuLinks';

import { useWindowWidth } from './hooks';

const HeaderNav = props => {
  const width = useWindowWidth();

  return (
    <Container {...props}>
      <h1>inova</h1>
      {width > 670 ? <Links /> : <MenuLinks />}
    </Container>
  );
};

export default HeaderNav;
