import { createGlobalStyle } from 'styled-components';

import CirceRegularFontTTF from '../assets/fonts/CirceRegular/Circe-Regular.ttf';
import CirceExtraLightFontTTF from '../assets/fonts/CirceExtraLight/Circe-ExtraLight.ttf';
import CirceLightFontTTF from '../assets/fonts/CirceLight/Circe-Light.ttf';
import CirceThinFontTTF from '../assets/fonts/CirceThin/Circe-Thin.ttf';
import CirceBoldFontTTF from '../assets/fonts/CirceBold/Circe-Bold.ttf';
import CirceExtraBoldFontTTF from '../assets/fonts/CirceExtraBold/Circe-ExtraBold.ttf';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Circe Sans';
    src: url(${CirceThinFontTTF});
    font-weight: 100;
    font-style: normal;
  }
  @font-face {
    font-family: 'Circe Sans';
    src: url(${CirceExtraLightFontTTF});
    font-weight: 200;
    font-style: normal;
  }
  @font-face {
    font-family: 'Circe Sans';
    src: url(${CirceLightFontTTF});
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Circe Sans';
    src: url(${CirceBoldFontTTF});
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Circe Sans';
    src: url(${CirceRegularFontTTF});
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'Circe Sans';
    src: url(${CirceExtraBoldFontTTF});
    font-weight: bolder;
    font-style: normal;
  }

  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    outline: 0;
  }
  html, body, #root {
    /* background-color: #323d64; */
    height: 100%;
  }
  body {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    font-family: 'Circe Sans', sans-serif;
  }
  input, button {
  }
  button {
    cursor: pointer;
  }
`;

export default GlobalStyles;
