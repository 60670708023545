import React from 'react';

import { Container, NumericCharacter, Title, Description } from './styles';

const Advantage = ({ number, title, description, ...rest }) => {
  return (
    <Container {...rest}>
      <NumericCharacter>{number}</NumericCharacter>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Container>
  );
};

export default Advantage;
