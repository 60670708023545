import React from 'react';
import { Divider } from 'antd';
import { isAndroid, isIOS } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple, faAndroid } from '@fortawesome/free-brands-svg-icons';

import {
  Container,
  ImageContainer,
  Image,
  ButtonsContainer,
  FirstButton,
  SecondButton
} from './styles';

const AppItem2 = ({
  image,
  title,
  description,
  landingPageUrl,
  appleStoreUrl,
  playStoreUrl,
  ...rest
}) => {
  const getUrlAccordingToDevice = () => {
    if (!isAndroid && !isIOS) {
      return landingPageUrl;
    } else if (isAndroid) {
      return playStoreUrl;
    } else if (isIOS) {
      return appleStoreUrl;
    }
  };

  return (
    <Container {...rest}>
      <ImageContainer>
        <a
          href={getUrlAccordingToDevice()}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image src={image} width={200} />
        </a>
      </ImageContainer>
      <ButtonsContainer>
        <FirstButton
          href={appleStoreUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faApple} size="2x" />
        </FirstButton>
        <Divider
          type="vertical"
          style={{ alignSelf: 'center', justifySelf: 'center' }}
        />
        <SecondButton
          href={playStoreUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faAndroid} size="2x" />
        </SecondButton>
      </ButtonsContainer>
    </Container>
  );
};

export default AppItem2;
