import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
`;

export const IntroContainerBackground = styled.div``;

export const IntroContainer = styled.div``;

export const Content = styled.div``;

export const FooterContainer = styled.div``;
