import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;
`;

export const Title = styled.h5`
  font-weight: bolder;
  font-size: 1rem;
  text-transform: uppercase;
  color: #9e9e9e;
`;
export const Description = styled.p`
  font-size: 2.5rem;
  font-weight: 200;
  color: #201f1f;

  @media screen and (min-width: 670px) {
    font-size: 3rem;
  }
`;

export const AdvantagesList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(285px, 1fr));
  grid-row-gap: 32px;
  grid-column-gap: 16px;

  margin-top: 16px;
`;
