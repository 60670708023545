import React, { Fragment } from 'react';
import { BrowserRouter } from 'react-router-dom';

import './styles/App.css';
import { Container, Content } from './styles/components';
import GlobalStyles from './styles/global';

import Routes from './routes/routes';

const App = () => (
  <Fragment>
    <GlobalStyles />

    <BrowserRouter>
      <Container>
        <Content>
          <Routes />
        </Content>
      </Container>
    </BrowserRouter>
  </Fragment>
);

export default App;
